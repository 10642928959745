import React, { createContext, useState, useContext } from 'react'
import { SearchContextState } from 'types/context'

const ISearchDefaultValue = {
  query: '',
  setQuery: (state: string) => {},
}

export const SearchContext =
  createContext<SearchContextState>(ISearchDefaultValue)

export const SearchProvider: React.FC = ({ children }) => {
  const [query, setQuery] = useState<string>('')

  const value = {
    query,
    setQuery,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export const useSearchContext = () => useContext(SearchContext)
