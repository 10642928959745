import React from 'react'

import './src/styles/_global.css'

import { ModalProvider } from './src/hooks/modal-context'
import { SearchProvider } from './src/hooks/search-context'
import { MenuProvider } from './src/hooks/menu-context'

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
      <SearchProvider>
        <MenuProvider>{element}</MenuProvider>
      </SearchProvider>
    </ModalProvider>
  )
}
